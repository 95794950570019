// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-diss-track-js": () => import("./../../../src/pages/diss-track.js" /* webpackChunkName: "component---src-pages-diss-track-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-priorities-austerity-js": () => import("./../../../src/pages/priorities/austerity.js" /* webpackChunkName: "component---src-pages-priorities-austerity-js" */),
  "component---src-pages-priorities-education-js": () => import("./../../../src/pages/priorities/education.js" /* webpackChunkName: "component---src-pages-priorities-education-js" */),
  "component---src-pages-priorities-nhs-js": () => import("./../../../src/pages/priorities/nhs.js" /* webpackChunkName: "component---src-pages-priorities-nhs-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */)
}

